import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/LeafletMapGroup"
import Seo from "../components/global/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title="Тахограф - ТахоПортал"
      description="Знайти вичерпну інформацію про Тахографи в Україні на порталі Taho.org.ua. Компанії, закони, інструкції, відгуки."
    />
    <div className="min-h-[500px]">
      {typeof window !== "undefined" && (
        <Map geoData={data.allTahoservCsv.nodes} />
      )}
    </div>

    <div className="max-w-7xl mx-auto px-4 sm:px-6 prose prose-xl my-12 sm:my-24">
      <h1>Тахограф</h1>
      <p>
        Тахограф - це пристрій що фіксує режим роботи, час відпочинку водіїв,
        швидкість і координати транспорту під час міжнародних комерційних
        перевезень. Показники знімаються автономно, щоб забезпечити контроль і
        об'єктивне покарання при порушенні вимог "Європейської угоди щодо роботи
        екіпажів транспортних засобів, що здійснюють міжнародні автомобільні
        перевезення" (ЄУТР - укр, або ЕСТР рос).
      </p>
      <p>Існує декілька формфакторів:</p>
      <ul>
        <li>круглі (можуть встановлюватися в гніздо спідометра),</li>
        <li>формату автомагнітоли.</li>
      </ul>
      <p>Також пристрої розділяють на:</p>
      <ul>
        <li>цифрові,</li>
        <li>аналогові (механічні та електронно-програмовані)</li>
      </ul>
      <p>
        Для запису інформації з аналогових пристроїв використовуються тахошайби,
        але цифрові пристрої є більш сучасними й майже замінили на ринку
        аналогових попередників. Інформація цифрового тахографа записується на
        чіп-карту - 28 попередніх та поточний день. Якщо інформації більше, то
        буде замінятися найбільш стара інформація.
      </p>
      <p>Окрім карти водія існують інші види чип-карт:</p>
      <ul>
        <li>
          чип-карта майстерні – дозволяє налаштовувати тахограф та змінювати
          його основні функціональні параметри;
        </li>
        <li>
          чип-карта компанії — дозволяє зчитувати дані автомобілів, що належать
          автопідприємству, також обмежувати доступ до інформації іншим
          компаніям;
        </li>
        <li>
          чип-карта інспектора — дозволяє зчитувати порушення швидкісного
          режиму, режиму праці та відпочинку, збої в роботі обладнання,
          відключення живлення або датчика швидкості.
        </li>
      </ul>
      <p>
        В Україні карти для цифрових тахографів випускає Державне підприємство
        "Державний автотранспортний науково-дослідний і проектний інститут" (ДП
        "ДержавтотрансНДІпроект").
      </p>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allTahoservCsv(sort: { fields: sortID, order: DESC }) {
      nodes {
        id
        mapLat
        mapLong
        title
        mapCity
        slug
        shortname
      }
    }
  }
`

export default IndexPage
