import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-markercluster"
// import { CogIcon } from "@heroicons/react/outline"
// import "./leafletmap.css"
import { slugify } from "../utils/transcriptSlugOld"

class LeafletMapGroup extends Component {
  static propTypes = {
    /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
    position: PropTypes.array,

    /** Initial zoom level for the map (default 13) **/
    zoom: PropTypes.number,

    /** If set, will display a marker, which when clicked will display this text **/
    markerText: PropTypes.element,

    geoData: PropTypes.array,
  }

  static defaultProps = {
    position: [49.3241712, 29.5735713],
    zoom: 7,
    markerText: null,
  }

  render() {
    return (
      <MapContainer
        center={this.props.position}
        zoom={this.props.zoom}
        className="z-0"
      >
        <TileLayer
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {this.props.geoData.length > 0 &&
            this.props.geoData.map(marker => (
              <Marker
                key={marker.id}
                position={[marker.mapLat, marker.mapLong]}
                // icon={CogIcon}
              >
                <Popup>
                  <Link to={`/${marker.slug}-${slugify(marker.shortname)}/`}>
                    {`${marker.title}, ${marker.mapCity}`}
                  </Link>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>
      </MapContainer>
    )
  }
}

export default LeafletMapGroup
